.app-conitainer {
  background: url("./bg_01.jpg") repeat center center;
  background-size: 36%;
  width: 100%;
  text-align: center;
  height: 100%;
  position: absolute;
}

.App-logo {
  height: 100%;
  pointer-events: none;
  position: fixed;
  z-index: 3;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

.App-link {
  color: #61dafb;
}

.colorful-backgrounds{
  width: 100%;
  height: 50%;
  background-color: green;
  display: block;
  position: absolute;
  bottom: 0;
  background: url("./bg_02.jpg") repeat center center;
  background-size: 45%;
  background-blend-mode: color;
}
.middle-text{
  background-color: black;
  position: fixed;
  top: 50%;
  width: 100%;
  z-index: 2;
  text-align: left;
  padding: 15px;
  font-weight: 400;
}
.middle-text a{
  color: lightgray;
  text-decoration: none;
  font-weight: 600;
  background-color: #28282f;
  padding: 15px 6px;
}